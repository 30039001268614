import React, { useState, useRef } from 'react';
import './Contact.css';

function Contact() {
  const [formData, setFormData] = useState({ nimi: '', email: '', viesti: '' });
  const [feedback, setFeedback] = useState('');

  const nimiRef = useRef(null);
  const emailRef = useRef(null);
  const viestiRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    e.target.setCustomValidity(''); // Clear custom validity on change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { nimi, email, viesti } = formData;

    // Set custom validity messages
    if (!nimi) {
      nimiRef.current.setCustomValidity('Nimi on pakollinen.');
    }
    if (!email) {
      emailRef.current.setCustomValidity('Sähköposti on pakollinen.');
    }
    if (!viesti) {
      viestiRef.current.setCustomValidity('Viesti on pakollinen.');
    }

    // Trigger validation
    if (nimi && email && viesti) {
      console.log(nimi, email, viesti)

      try {
        // Send the form data to the PHP script
        const response = await fetch('Contact.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams(formData), // Encode form data
        });

        if (response.ok) {
          setFeedback('Kiitos yhteydenotostasi!'); // Success message
          setFormData({ nimi: '', email: '', viesti: '' }); // Clear the form
        } else {
          setFeedback('Viestiä ei voitu lähettää. Yritä uudelleen.');
        }
      } catch (error) {
        setFeedback('Virhe: ' + error.message); // Handle any errors
      }
    } else {
      nimiRef.current.reportValidity();
      emailRef.current.reportValidity();
      viestiRef.current.reportValidity();
    }
  };

  return (
    <div className="w-full pt-20 pb-10 px-10" id="contact-bigger-container">
      <h1 className="w-full text-center text-3xl font-bold mb-8 contact-text">Yhteystiedot</h1>
      <div id="contactcontainer">
        <div className="contact-info">
          <p>
            Jaana Siekkinen ja Minna Suomalainen<br />
            puh. 050 597 4247<br />
            arkiapuminja@gmail.com<br /> <br />
            Ota yhteyttä, niin sovitaan teidän tarpeisiin sopivat palvelut<br /><br />
            {/* Voit myös lähettää meille suoraan viestiä yhteydenottolomakkeella! */}
          </p>
        </div>

        {/* <form className="contact-form mt-8" onSubmit={handleSubmit}>
          <label htmlFor="nimi">Nimi:</label>
          <input
            type="text"
            id="nimi"
            name="nimi"
            ref={nimiRef}
            value={formData.nimi}
            onChange={handleChange}
            required
          />

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            ref={emailRef}
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label htmlFor="viesti">Viestisi:</label>
          <textarea
            id="viesti"
            name="viesti"
            ref={viestiRef}
            value={formData.viesti}
            onChange={handleChange}
            required
          ></textarea>

          <button type="submit">Lähetä</button>
          <p className="feedback">{feedback}</p>
        </form> */}
      </div>

      <hr className="my-8 mx-auto w-9/10 border-t-2 border-gray-300" />

      <div className="w-full flex flex-col items-center text-center mt-4" id="contactcontainer2">
        <p>
          Arkiapu MinJa Oy - Hämeenlinna <br />
          Y-tunnus 3471616-2 <br /> <br />
          <a
            href="/images/sosiaalipalvelujen-omavalvontasuunnitelma-arkiapuminja.pdf"
            target="_blank"
            className="text-black-300 hover:underline"
          >
            Omavalvontasuunnitelma
          </a>
        </p>

      </div>

    </div>
  );
}

export default Contact;
