import React from 'react';
import './Services.css';

function Services() {
  return (

    <div class="max-w-7xl mx-auto p-10 pt-20" id="service-section">
        <h1 class="service-text">Palvelumme</h1>
        <div class="flex flex-col justify-around items-center text-center mb-4 space-y-10 md:space-y-0 md:flex-row">
            <h2>Alla listattuna palvelumme kotiin ja palveluasumiseen.<br></br>
            Suluissa hinta kotitalousvähennyksellä, joka on vuonna 2025 35%. <br></br>
            Palvelumme ovat arvonlisäverottomia.
            </h2>
        </div>

        <div class="flex flex-col justify-around items-start text-center mb-4 space-y-10 md:space-y-0  md:flex-row">
            <div class="flex flex-col items-center text-center">
                <img src="/images/icon1-minja-vari-01.png" alt="raivaussiivous, arkiapuminja" class="w-30 h-28 mb-2"/>
                <p class="font-semibold">Raivaussiivous 55€/h (35,75€/h)</p>
                <p class="font-light p-5">Muut kulut: Kaatopaikkamaksu, tarvittaessa jätelavamaksu, matkakorvaus kohteen
                    ja kaatopaikan välillä 0,59€/km</p>
            </div>
            <div class="flex flex-col items-center text-center">
                <img src="/images/icon2-minja-vari-01.png" alt="muuttoapu, arkiapuminja" class="w-30 h-28 mb-2"/>
                <p class="font-semibold">Muuttoapu 45€/h (29,25€/h) </p>
                <p class="font-light p-5">Pakkaaminen, pienempien tavaroiden kuljetus</p>
            </div>
            <div class="flex flex-col items-center text-center">
            <img src="/images/icon8-minja-vari-01.png" alt="omais-ja läheishoitajien sijaistaminen" class="w-30 h-28 mb-2"/>
                <p class="font-semibold">Omais- ja läheishoitajien sijaistaminen 40€/h (26€/h) </p>
                <p class="font-light p-5" id="invisibleplaceholder">Omais- ja läheishoitajien sijaistaminen 40€/h (16€/h)</p>
           
            </div>  
        </div>

        <div class="flex flex-col justify-around items-start text-center mb-4 space-y-10 md:space-y-0 md:flex-row">
            <div class="flex flex-col items-center text-center">
            <img src="/images/icon6-minja-vari-01.png" alt="vaate-ja tekstiilihuolto" class="w-30 h-28 mb-2"/>
                <p class="font-semibold">Vaate- ja tekstiilihuolto 40€/h (26€/h)</p>
                <p class="font-light p-5">Pyykkihuolto ja lakanoiden vaihtaminen</p>
                
            </div>
            <div class="flex flex-col items-center text-center">
                <img src="/images/icon5-minja-vari-01.png" alt="ruokahuolto, arkiapuminja" class="w-30 h-28 mb-2"/>
                <p class="font-semibold">Ruokahuolto 40€/h (26€/h)</p>
                <p class="font-light p-5">Asiakkaan kotona tapahtuva ruuan laittaminen</p>
            </div>
            <div class="flex flex-col items-center text-center">

            <img src="/images/icon4-minja-vari-01.png" alt="ulkoiluapu, arkiapuminja" class="w-30 h-28 mb-2"/>
                <p class="font-semibold">Ulkoilu- ja virkistyskäynnit 40€/h (26€/h)</p>
                <p class="font-light p-5" id="invisibleplaceholder">Kotona ja palveluasumisessa</p>
                
            </div>
        </div>

        <div class="flex flex-col justify-around items-start text-center mb-4 space-y-10 md:space-y-0 md:flex-row">
        <div class="flex flex-col items-center text-center">
                <img src="/images/icon3-minja-vari-01.png" alt="asiointiapu, arkiapuminja" class="w-30 h-28 mb-2"/>
                <p class="font-semibold">Asiointiapu 40€/h (26€/h)</p>
                <p class="font-light p-5">Kauppa, pankki,apteekki ja lääkärissä asiointi/saattaminen. Myös hakemusten täyttäminen asiakkaan kotona ja digiapu.</p>
            </div>
        </div>

    </div>

    
  );
}

export default Services;

